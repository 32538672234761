import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SelectField = ({
  id,
  name,
  label,
  required,
  options,
  placeholder,
  disabled,
}) => {
  const [field, meta, helpers] = useField({ id, name });

  const handleChange = event => {
    helpers.setValue(event.target.value);
  };

  const hasError = meta.error && meta.touched;

  return (
    <div>
      <label className="block text-sm font-bold text-gray-700" htmlFor={id}>
        {label ? (
          <>
            <div className="inline-block">{label}</div>
            {required ? (
              <div className="inline-block ml-1 text-red-600">*</div>
            ) : null}{' '}
          </>
        ) : null}
        <div className="relative inline-block w-full">
          <select
            id={id}
            name={name}
            value={field.value}
            disabled={disabled}
            className={
              `${hasError ? 'border-red-400 border-2' : 'border'}` +
              `${field.value ? ' text-gray-900' : ' text-gray-500'}` +
              ' mt-2 bg-white shadow appearance-none rounded-none hover:border-gray-500 w-full px-3 h-12' +
              ' text-base leading-6 focus:outline-none focus:shadow-outline'
            }
            onChange={handleChange}
            onBlur={field.onBlur}
          >
            <option value="" disabled hidden>
              {placeholder}
            </option>
            {options.map(elm => (
              <option key={`${elm}`} className="leading-6">
                {elm}
              </option>
            ))}
          </select>
          <div
            className={
              'absolute inset-y-0 right-0 flex items-center px-4 pt-2 text-gray-700' +
              ' pointer-events-none'
            }
          >
            <FontAwesomeIcon
              className="inline-block text-gray-700"
              icon="chevron-down"
            />
          </div>
        </div>
      </label>
    </div>
  );
};
SelectField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};
SelectField.defaultProps = {
  label: '',
  options: [],
  required: false,
  placeholder: 'Choose',
  disabled: false,
};
export default SelectField;
