import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

const EmailInputField = ({
  id,
  name,
  label,
  placeholder,
  required,
  disabled,
}) => {
  const [field, meta] = useField({ id, name });
  const hasError = meta.error && meta.touched;
  return (
    <div>
      <label className="block text-sm font-bold text-gray-700" htmlFor={id}>
        {label ? (
          <div>
            <div className="inline-block">{label}</div>
            {required ? (
              <div className="inline-block ml-1 text-red-600">*</div>
            ) : null}
          </div>
        ) : null}
        <input
          id={id}
          name={name}
          type="email"
          placeholder={placeholder}
          disabled={disabled}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          className={
            `${hasError ? 'border-red-400 border-2' : 'border'}` +
            ' mt-2 shadow appearance-none rounded-none hover:border-gray-500 w-full py-2 px-3 h-12' +
            ' text-base text-gray-900 leading-normal placeholder-gray-500 focus:outline-none focus:shadow-outline'
          }
        />
      </label>
    </div>
  );
};
EmailInputField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};
EmailInputField.defaultProps = {
  label: '',
  placeholder: 'Type here',
  required: false,
  disabled: false,
};
export default EmailInputField;
