import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import BeatLoader from 'react-spinners/BeatLoader';

import contactTitle from 'images/element/footer/contact-title.svg';
import linkTitle from 'images/element/footer/link-title.svg';
import newsletter from 'images/element/footer/newsletter.svg';
import logoWhite from 'images/element/footer/logo-white.svg';
import contact from 'data/contact.json';

import { EmailInputField } from 'components/shared/form';
import { getFormApiURL } from 'components/shared/apiURL';

const ComponentText = {
  NEWSLETTER_SUBTITLE:
    'Subscribe our newsletter or get notification about new updates, information discount.',
  EMAIL_PLACEHOLDER: 'Enter Your Email',
  SUBSCRIBE_SUBMIT_BUTTON: 'Subscribe',
  REQUIRED_VALIDATION: 'ต้องระบุข้อมูล',
  COMPANY_STUDIO: '2465STUDIO',
  COMPANY_RESERVED: '2019 - All rights reserved',
  SUBSCRIBE_SUCCESS: 'SUBSCRIBED',
  SUBSCRIBE_FAILURE: 'SUBSCRIBE FAILURE',
};

const initialValues = {
  email: '',
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required(ComponentText.REQUIRED_VALIDATION),
});

const linkList = [
  {
    name: 'home',
    to: '/',
  },
  {
    name: 'services',
    to: '/services',
  },
  {
    name: 'team',
    to: '/team',
  },
  {
    name: 'contact',
    to: '/contact',
  },
  {
    name: 'blogs',
    to: '/blogs',
  },
];

const formURL = getFormApiURL();

const reducer = (state, action) => {
  switch (action.type) {
    case 'init': {
      return {
        ...state,
        status: 'idle',
      };
    }
    case 'request': {
      return {
        ...state,
        status: 'pending',
      };
    }
    case 'success': {
      return {
        ...state,
        status: 'resolved',
      };
    }
    case 'error': {
      return {
        ...state,
        status: 'rejected',
        error: action.error,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const SocialMenuItem = ({ iconName, href }) => {
  return (
    <li className="flex items-center w-6 h-6">
      <a href={href}>
        <FontAwesomeIcon
          className="text-lg text-white fab fa-facebook leading-lg"
          icon={['fab', iconName]}
        />
      </a>
    </li>
  );
};

const Footer = () => {
  const [state, transit] = React.useReducer(reducer, {
    status: 'idle',
    error: null,
  });

  const handleSubscribeSubmit = async values => {
    transit({ type: 'request' });
    try {
      const response = await axios.post(formURL, {
        ...values,
        type: 'newsletterSubscription',
      });
      if (response && response.status === 200) {
        transit({ type: 'success' });
      } else {
        console.error(`Error: Send mail error`);
        transit({ type: 'error' });
      }
    } catch (error) {
      console.error(`Error`, error);
      transit({ type: 'error' });
    }
  };

  const form = (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubscribeSubmit}
    >
      {formikBag => {
        const isRequestPending = state.status === 'pending';
        const disabledSubmit =
          !formikBag.dirty || !formikBag.isValid || isRequestPending;
        // console.log(`Value`, formikBag.values);
        return (
          <form
            autoComplete="off"
            onSubmit={formikBag.handleSubmit}
            className="w-full"
          >
            <div className="flex flex-col">
              <EmailInputField
                required
                id="email"
                name="email"
                placeholder={ComponentText.EMAIL_PLACEHOLDER}
                disabled={isRequestPending}
              />
              <div className="mt-3">
                <button
                  type="submit"
                  disabled={disabledSubmit}
                  className={
                    `${disabledSubmit ? 'cursor-not-allowed' : 'cursor-auto'}` +
                    ' w-40 py-2 bg-teal-700 border-gray-200 border text-gray-200 font-bold' +
                    ' hover:bg-teal-800'
                  }
                >
                  <div className="flex items-center justify-center">
                    {isRequestPending ? (
                      <BeatLoader
                        loading
                        color="#dfdfdf"
                        size={12}
                        margin={0}
                      />
                    ) : (
                      ComponentText.SUBSCRIBE_SUBMIT_BUTTON
                    )}
                  </div>
                </button>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
  return (
    <footer className="flex flex-col w-full bg-main">
      <div className="flex flex-col items-center justify-center w-full py-12 lg:py-16">
        <div className="px-10 grid grid-cols-1 gap-16 md:px-8 lg:px-0 md:grid-cols-3 max-w-screen-lg">
          <div className="flex flex-col">
            <img
              src={contactTitle}
              alt="Contact us"
              className="object-cover w-40 h-auto"
            />
            <div className="inline-flex items-center mt-3">
              <FontAwesomeIcon
                className="text-sm text-white"
                icon={['fas', 'phone-alt']}
              />
              <div className="ml-3 text-sm text-gray-200">{contact.phone}</div>
            </div>
            <div className="inline-flex items-center mt-2">
              <FontAwesomeIcon
                className="text-sm text-white"
                icon={['fas', 'map-marked-alt']}
              />
              <div className="ml-3 text-sm text-gray-200 whitespace-normal">
                {`${contact.address1}, ${contact.address2}`}
              </div>
            </div>
            <div className="inline-flex items-center mt-2">
              <FontAwesomeIcon
                className="text-sm text-white"
                icon={['fas', 'envelope']}
              />
              <div className="ml-3 text-sm text-gray-200 whitespace-normal">
                {contact.email}
              </div>
            </div>
          </div>

          <div className="flex lg:justify-center">
            <div className="">
              <img
                src={linkTitle}
                alt="Our Links"
                className="object-cover h-auto"
                style={{ width: 140 }}
              />
              <div className="mt-3 grid grid-cols-2 md:grid-cols-1 gap-4">
                {linkList.map(link => {
                  return (
                    <Link key={link.name} to={link.to}>
                      <div className="flex items-center">
                        <div className="flex items-center">
                          <FontAwesomeIcon
                            className="text-sm text-white"
                            icon={['fas', 'angle-double-right']}
                          />
                          <div className="ml-3 text-base text-gray-200 uppercase">
                            {link.name}
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="flex lg:justify-center">
            <div className="flex flex-col">
              <img
                src={newsletter}
                alt="Newsletter"
                className="object-cover w-40 h-auto"
              />
              <div className="mt-3 text-sm font-semibold text-white">
                {ComponentText.NEWSLETTER_SUBTITLE}
              </div>
              <div className="flex flex-col items-center justify-center h-full mt-2">
                {state.status === 'resolved' ? (
                  <div className="flex items-center w-full">
                    <FontAwesomeIcon
                      className="text-3xl text-teal-200"
                      icon={['fas', 'check-circle']}
                    />
                    <div className="ml-3 text-base text-teal-200">
                      {ComponentText.SUBSCRIBE_SUCCESS}
                    </div>
                  </div>
                ) : (
                  form
                )}
                {state.status === 'rejected' ? (
                  <div className="flex items-center w-full mt-3">
                    <FontAwesomeIcon
                      className="text-xl text-red-300"
                      icon={['fas', 'exclamation-circle']}
                    />
                    <div className="ml-3 text-base text-red-300">
                      {ComponentText.SUBSCRIBE_FAILURE}
                    </div>
                  </div>
                ) : null}
              </div>
              <ul className="flex items-center justify-center w-full mt-6 list-none md:w-auto">
                <SocialMenuItem
                  title="facebook"
                  href={contact.facebook}
                  iconName="facebook-f"
                />
              </ul>
              <div className="flex items-center justify-center mt-6">
                <img
                  src={logoWhite}
                  alt="Alemana"
                  className="object-cover w-40 h-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center py-1 bg-secondary">
        <div className="flex items-center px-8 max-w-screen-lg">
          <div className="text-sm font-semibold" style={{ color: '#013035' }}>
            {ComponentText.COMPANY_STUDIO}
          </div>
          <div className="text-sm text-gray-300 font-semibold ml-2">
            {ComponentText.COMPANY_RESERVED}
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;

/*

  <SocialMenuItem
    title="instagram"
    href="#"
    iconName="instagram"
  />
  <SocialMenuItem title="skype" href="#" iconName="skype" />
  <SocialMenuItem title="twitter" href="#" iconName="twitter" />
  <SocialMenuItem
    title="linkedin"
    href="#"
    iconName="linkedin-in"
  />


  <div className="flex items-center mt-2">
    <FontAwesomeIcon
      className="text-sm text-white"
      icon={['fab', 'skype']}
    />
    <div className="ml-3 text-sm text-gray-200 whitespace-normal">
      {contact.skype}
    </div>
  </div>

*/
