import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';

const CheckboxGroupField = ({ name, label, required, options }) => {
  return (
    <FieldArray
      name={name}
      render={arrayHelpers => {
        /* I decide to create this component to work as a whole group of form elements.
         * Below code is not work because each form elements is not Formik Field.
         * If we try to create each component as a field, we have to check all the touched of 
         * each elements.
         * So right now I still not find the solution to display error message.
        import { getIn } from 'formik';
        const error = getIn(arrayHelpers.form.errors, name);
        const touched = getIn(arrayHelpers.form.touched, name);
        const hasError = error && touched;
        console.log(`Error`, hasError, arrayHelpers.form.touched);
        */
        return (
          <div>
            <div className="inline-block text-sm font-bold text-gray-700">
              {label}
            </div>
            {required ? (
              <div className="inline-block ml-1 text-red-600">*</div>
            ) : null}

            <div className="flex flex-col">
              {options.map(op => (
                <label
                  key={op.id}
                  className="inline-flex items-center mt-2"
                  htmlFor={op.id}
                >
                  <input
                    type="checkbox"
                    name={name}
                    value={op.value}
                    className="w-6 h-6 form-checkbox lg:w-5 lg:h-5"
                    onChange={e => {
                      if (e.target.checked) {
                        arrayHelpers.push(op.value);
                      } else {
                        const selected = arrayHelpers.form.values[name];
                        const idx = selected.findIndex(elm => elm === op.value);
                        arrayHelpers.remove(idx);
                      }
                    }}
                  />
                  <span className="ml-2">{op.value}</span>
                </label>
              ))}
            </div>
          </div>
        );
      }}
    />
  );
};
CheckboxGroupField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  required: PropTypes.bool,
};
CheckboxGroupField.defaultProps = {
  options: [],
  required: false,
};
export default CheckboxGroupField;
