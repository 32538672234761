import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import logo from 'images/element/logo.svg';
import contact from 'data/contact.json';

const ComponentText = {
  HOME_MENU: 'HOME',
  SERVICES_MENU: 'SERVICES',
  TEAM_MENU: 'TEAM',
  CONTACT_MENU: 'CONTACT',
  BLOG_MENU: 'BLOGS',
};

const MenuItem = ({ title, href }) => {
  return (
    <li className="flex items-center w-full lg:w-auto">
      <a
        className={
          'hover:text-gray-400 text-gray-800 lg:text-gray-200' +
          ' w-full px-3 py-4 lg:py-2 flex items-center justify-end text-sm lg:text-sm uppercase font-bold'
        }
        href={href}
      >
        {title}
      </a>
    </li>
  );
};

const SocialMenuItem = ({ iconName, href }) => {
  return (
    <li className="flex items-center w-6 h-6">
      <a href={href}>
        <FontAwesomeIcon
          className="text-lg text-white fab fa-facebook leading-lg"
          icon={['fab', iconName]}
        />
      </a>
    </li>
  );
};

const NavBar = ({ navBarHeight, topNavBarHeight }) => {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
      <nav
        className={'fixed top-0 z-50 w-full'}
        style={{ height: navBarHeight }}
      >
        <div className="relative flex flex-col flex-wrap w-full">
          <div
            className="absolute top-0 left-0 z-10 items-center justify-center hidden px-6 md:flex"
            style={{ height: navBarHeight }}
          >
            <Link to="/">
              <img src={logo} alt="logo" className="w-56 h-auto mb-4" />
            </Link>
          </div>
          <div
            className="flex justify-start w-full px-0 bg-main md:px-2 md:justify-end"
            style={{ height: topNavBarHeight }}
          >
            <div className="flex items-center flex-shrink-0 h-full px-8 bg-transparent md:bg-white-opacity-2/10">
              <FontAwesomeIcon
                className="text-lg text-white leading-lg"
                icon={['fas', 'phone-alt']}
              />
              <div className="ml-2 text-sm leading-tight text-white">
                {contact.phone}
              </div>
            </div>
            <ul className="flex items-center justify-end w-full h-full ml-6 list-none md:w-auto">
              <SocialMenuItem
                title="facebook"
                href={contact.facebook}
                iconName="facebook-f"
              />
            </ul>
          </div>
          <div
            className="relative flex items-center justify-end w-full px-2 bg-main-gradient-opacity-9/10 lg:static"
            style={{
              height: navBarHeight - topNavBarHeight,
            }}
          >
            <div
              className="absolute top-0 left-0 z-10 flex items-center justify-center px-6 py-1 md:hidden"
              style={{ height: navBarHeight - topNavBarHeight }}
            >
              <Link to="/">
                <img src={logo} alt="logo" className="w-auto h-full" />
              </Link>
            </div>
            <div className="flex justify-end w-full relative">
              <button
                className={
                  'block px-2 py-1 text-xl leading-none bg-transparent' +
                  ' border border-transparent border-solid rounded' +
                  ' outline-none cursor-pointer lg:hidden focus:outline-none'
                }
                type="button"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <FontAwesomeIcon
                  className="text-gray-200"
                  icon={['fas', 'bars']}
                />
              </button>
            </div>
            <div
              className={
                'flex-grow items-center justify-end w-1/3 overflow-hidden' +
                ' lg:flex lg:bg-transparent lg:shadow-none lg:px-0 lg:w-full' +
                (navbarOpen ? ' fixed block rounded shadow-lg' : ' hidden')
              }
              style={
                navbarOpen
                  ? { top: navBarHeight, right: 14 }
                  : { top: 0, right: 0 }
              }
            >
              <ul
                className={
                  'flex flex-col items-end list-none w-full bg-white lg:flex-row lg:justify-end lg:bg-transparent'
                }
              >
                <MenuItem title={ComponentText.HOME_MENU} href="/" />
                <MenuItem
                  title={ComponentText.SERVICES_MENU}
                  href="/services"
                />
                <MenuItem title={ComponentText.TEAM_MENU} href="/team" />
                <MenuItem title={ComponentText.CONTACT_MENU} href="/contact" />
                <MenuItem title={ComponentText.BLOG_MENU} href="/blogs" />
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
NavBar.propTypes = {
  navBarHeight: PropTypes.number.isRequired,
  topNavBarHeight: PropTypes.number.isRequired,
};
NavBar.defaultProps = {};
export default NavBar;

/*
  <SocialMenuItem title="instagram" href="#" iconName="instagram" />
  <SocialMenuItem title="skype" href="#" iconName="skype" />
  <SocialMenuItem title="twitter" href="#" iconName="twitter" />
  <SocialMenuItem
    title="linkedin"
    href="#"
    iconName="linkedin-in"
  />
*/
