import React from 'react';
import PropTypes from 'prop-types';

import { Footer } from 'components/footer';
import NavBar from './NavBar';
import Dimension from './dimension';

const Layout = ({ renderContent }) => {
  const getWindowSize = () => {
    const height = typeof window !== `undefined` ? window.innerHeight : 0;
    const width = typeof window !== `undefined` ? window.innerWidth : 0;
    return {
      height,
      width,
    };
  };

  const [windowSize, setWindowSize] = React.useState(getWindowSize());

  React.useEffect(() => {
    let vh = windowSize.height * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, [windowSize.height]);

  const handleResize = () => {
    setWindowSize({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="h-full antialiased">
      <NavBar
        navBarHeight={Dimension.NAVBAR_HEIGHT}
        topNavBarHeight={Dimension.TOP_NAVBAR_HEIGHT}
      />
      <main className="flex flex-col items-center w-full">
        {renderContent({
          contentSize: {
            width: windowSize.width,
            height: windowSize.height - Dimension.TOP_NAVBAR_HEIGHT,
          },
        })}
      </main>
      <div data-sal="fade" data-sal-delay="50" data-sal-easing="ease">
        <Footer />
      </div>
    </div>
  );
};
Layout.propTypes = {
  renderContent: PropTypes.func,
};
Layout.defaultProps = {
  renderContent: () => <div />,
};
export default Layout;
